import React from 'react'
import Layout from '../components/shared/Layout'
import SEO from '../components/seo'

const Stationary = () => {
    return (
        <Layout>
            <SEO title="Aksidens"/>
            <div className="wrap" role="document">
                <div className="content">

                    <main className="main" id="main">
                        <header className="header">
                            <div className="container">
                                <div className="header__caption">
                                    <h1>Aksidens og <br/>trykksaker</h1>
                                </div>
                            </div>
                        </header>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="submenu">
                                        <ul className="submenu__list">
                                            <li className="submenu__item"><a href="#paper">Papirkvalitet</a></li>
                                            <li className="submenu__item"><a href="#card-template">Visittkortmal</a></li>
                                            <li className="submenu__item"><a href="#envelop-1-template">Konvolutter - Kjede</a></li>
                                            {/* <li className="submenu__item"><a href="#envelop-2-template">Konvolutter - Kontor</a></li> */}
                                            <li className="submenu__item"><a href="#word-template">Brevarkmal</a></li>
                                            <li className="submenu__item"><a href="#powerpoint-template">Presentasjonsmal</a></li>
                                            <li className="submenu__item"><a href="#ad-template">Annonsemal</a></li>
                                            <li className="submenu__item"><a href="#signature-template">Signaturmal</a></li>
                                            <li className="submenu__item"><a href="#folder-template">Omslagsmappe</a></li>
                                            <li className="submenu__item"><a href="#block-template">Blokker</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <p className="ingress lead">Her finnes maler på ferdig utviklet materiell.</p>
                                    <p className="ingrss lead">
                                        De malene som er laget for trykksaker ligger i Indesign, og 
                                        lar seg ikke åpne på PC´er som ikke har dette programmet. 
                                        Dette er et program som trykkerier og byråer har, så de 
                                        kan laste ned filene direkte herfra.
                                    </p>

                                    <hr/>

                                    <div id="paper" className="section">
                                        <h2>Papirkvalitet</h2>
                                        <p>Til trykksaker brukes papirtypen Soporset, der det er mulig. Soporset er et trefritt, ubestrøket papir med høy bulk og høy hvithet. Det har en jevn, mattkalandrert overflate og egner seg til en rekke forskjellige trykksaker.</p>
                                    </div>

                                    <hr/>

                                    <div id="card-template" className="section">
                                        <h2>Visittkortmal</h2>
                                        <div className="container-fluid no-padding">
                                            <div className="row no-gutters spacing-top">
                                                <div className="col-md-5">
                                                    <p>Visittkortet har to sider. På den ene siden står juridisk selskapslogo, og den andre siden inneholder individuell informasjon.</p>
                                                    <p>Papirkvalitet: Soporset 350gr</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Saga KL_Visittkort.indd.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned visittkortmal</a>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/>

                                    <div id="envelop-1-template" className="section">
                                        <h2>Konvoluttmal - Kjede</h2>
                                        <p>Konvoluttene har kurvemønsteret vertikalt på venstre siden og kjedelogo nederst til høyre. Adressefeltet står på flippen på baksiden. Det er tre konvolutter tilgjengelige.</p>
                                        <p>
                                            En mer kostnadseffektiv variant av konvolutter er den enkle utgaven med felles hovedlogo. Kjedekontoret trykker opp et felles opplag, og bestillinger kan gjøres direkte.
                                        </p>
                                        <div className="container-fluid no-padding">
                                            <div className="row no-gutters spacing-top">
                                                <div className="col-md-5">
                                                    <p>E65(110 x 220 mm)</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Saga KL_E65 med vindu.indd.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned E65 Konvoluttmal</a>
                                                    {/* <p>Har du problemer med å åpne indesignfilen, prøv å laste ned denne IDML filen(CS4/5)</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/konvolutter/kjede/idml/E65%20med%20vindu_kjede.idml.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned E65 IDML mal</a> */}
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_2.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                                <div className="col-md-5">
                                                    <p>C5</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Saga KL_C5 med vindu.indd.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned C5 Konvoluttmal</a>
                                                    {/* <p>Har du problemer med å åpne indesignfilen, prøv å laste ned denne IDML filen(CS4/5)</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/konvolutter/kjede/idml/C5%20med%20vindu_kjede.idml.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned C5 IDML mal</a> */}
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_2.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                                <div className="col-md-5">
                                                    <p>C4</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Saga KL_C4.indd.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned C4 Konvoluttmal</a>
                                                    {/* <p>Har du problemer med å åpne indesignfilen, prøv å laste ned denne IDML filen(CS4/5)</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/konvolutter/kjede/idml/C4_kjede.idml.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned C4 IDML mal</a> */}
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_3.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/>

                                    {/* <div id="envelop-2-template" className="section">
                                        <h2>Konvoluttmal - Kontor</h2>
                                        <p>Konvoluttene har kurvemønsteret vertikalt på venstre siden og juridisk selskapslogo nederst til høyre. Adressefeltet står på flippen på baksiden. Det er tre konvolutter tilgjengelige.</p>
                                        <div className="container-fluid no-padding">
                                            <div className="row no-gutters spacing-top">
                                                <div className="col-md-5">
                                                    <p>E65(110 x 220 mm)</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/konvolutter/kontor/E65%20med%20vindu_kontor.indd.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned E65 Konvoluttmal</a>
                                                    <p>Har du problemer med å åpne indesignfilen, prøv å laste ned denne IDML filen(CS4/5)</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/konvolutter/kontor/idml/E65%20med%20vindu_kontor.idml.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned E65 IDML mal</a>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_4.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                                <div className="col-md-5">
                                                    <p>C5</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/konvolutter/kontor/C5%20med%20vindu_kontor.indd.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned C5 Konvoluttmal</a>
                                                    <p>Har du problemer med å åpne indesignfilen, prøv å laste ned denne IDML filen(CS4/5)</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/konvolutter/kontor/idml/C5%20med%20vindu_kontor.idml.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned C5 IDML mal</a>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/stationary_11.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                                <div className="col-md-5">
                                                    <p>C4</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/konvolutter/kontor/C4_kontor.indd.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned C4 Konvoluttmal</a>
                                                    <p>Har du problemer med å åpne indesignfilen, prøv å laste ned denne IDML filen(CS4/5)</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/konvolutter/kontor/idml/C4_kontor.idml.zip" className="btn button button--download mx-auto button--two"><i className="fa fa-arrow-down icon--underline"></i> Last ned C4 IDML mal</a>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/stationary_10.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/> */}

                                    <div id="word-template" className="section">
                                        <h2>Brevmal</h2>
                                        <div className="container-fluid no-padding">
                                            <div className="row no-gutters spacing-top">
                                                <div className="col-md-5">
                                                    <p>
                                                        Dette er en Word-mal som alle kontorene må ta utgangspunkt i. Dette gjøres ved å lage et toppbilde med egen logo og adresse. Deretter erstatter dere bildet i malen med eget bilde. Det kreves indesign for å gjøre dette.
                                                    </p>
                                                    <p>
                                                        Dersom det trengs assistanse til dette, kan tjenesten kjøpes av designbyrået.
                                                    </p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Saga KL_brevark.dotx.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned wordmal</a>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_5.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/>

                                    <div id="powerpoint-template" className="section">
                                        <h2>Presentasjonsmal</h2>
                                        <div className="container-fluid no-padding">
                                            <div className="row no-gutters spacing-top">
                                                <div className="col-md-5">
                                                    <p>Powerpoint-mal.</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Saga_KL.potx.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned powerpointmal</a>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_6.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/>
                                <div id="ad-template" className="section">
                                        <h2>Annonsemal</h2>
                                        <div className="container-fluid no-padding">
                                            <div className="row no-gutters spacing-top">
                                                <div className="col-md-5">
                                                    <p>Dette er en mal som ligger i indesign for å kunne lage en trykklar fil. Trykkerier eller aviser har programvaren, og kan laste malen direkte ned herfra.</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Saga KL_Stillingsannonse M22.indd.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned annonsemal</a>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_10.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                <div id="signature-template" className="section">
                                        <h2>E-post signaturmal</h2>
                                        <div className="container-fluid no-padding">
                                            <div className="row no-gutters spacing-top">
                                                <div className="col-md-5">
                                                    <p>Denne Wordmalen er generell. Teksten må erstattes med riktig info, og logoen må byttes med din egen versjon. Bruk logoversjonen som er lagret som png. Vær nøye med at størrelse og plassering blir riktig når du setter den inn på Wordmalen.</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Mailsignatur master_Saga_KL.docx.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned signaturmal</a>
                                                </div>
                                                <div className="col-md-7">
                                                    {/* <img className="img-fluid" src="../img/sections/aksidens/stationary_8.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/>
                                <div id="folder-template" className="section">
                                        <h2>Omslagsmappe</h2>
                                        <div className="container-fluid no-padding">
                                            <div className="row no-gutters spacing-top">
                                                <div className="col-md-5">
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Saga KL_omslagsmappe.indd.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned omslagsmappe</a>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_7.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr/>

                                    <div id="block-template" className="section">
                                        <h2>Blokker</h2>
                                        <div className="container-fluid no-padding">
                                            <div className="row no-gutters spacing-top">
                                                <div className="col-md-5">
                                                    <p>A4-blokk</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Saga KL_notatblokk A4.indd.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned A4-blokk</a>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_9.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                            </div>
                                            <div className="row no-gutters spacing-top">
                                                <div className="col-md-5">
                                                    <p>10x21 blokk</p>
                                                    <a href="https://sagaservices.blob.core.windows.net/downloads/stationary/Saga KL_notatblokk 21x10.indd.zip" className="btn button button--download mx-auto"><i className="fa fa-arrow-down icon--underline"></i> Last ned 10x21-blokk</a>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="img-fluid" src="../img/sections/aksidens/Saga_KL_previewbilder_profilmanual_8.jpg" alt="DP Profilmanual LOGOPRIMARY ALLE 900x600"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>

                </div>
            </div>
        </Layout>
    )
}

export default Stationary